<template>
  <div class="w-full flex flex-col gap-4">
    <!-- Search -->
    <div class="w-full flex flex-row justify-between items-start gap-4">
      <div class="w-4/5">
        <SearchInput
          v-if="tab == 1"
          v-model="searchInput"
          width="80%"
          label="Buscar pelo nome, CRM ou CRMUF"
          :searchFunction="getCompanyDoctors"
        />
        <SearchInput
          v-if="tab == 2"
          v-model="searchInput"
          width="80%"
          label="Buscar pelo nome, CRM ou CRMUF"
          :searchFunction="getCompanyPendingDoctors"
        />
      </div>
      <div class="w-1/10">
        <FilledButton
          @click="isFilterApplied ? clearFilters() : openFilterModal()"
          :text="isFilterApplied ? 'Limpar Filtros' : 'Filtrar'"
          width="100%"
          height="56px"
          :prepend-icon="!isFilterApplied ? mdiFilter : ''"
        />
      </div>
      <div class="w-1/5">
        <FilledButton
          @click="openCreateInviteModal"
          text="Criar convite"
          width="100%"
          height="56px"
        />
      </div>
      <!-- <div v-show="tab === 1">
        <div class="w-full flex sm:flex-row flex-col gap-4">
          <SearchInput
            v-model="searchInput"
            width="100%"
            label="Buscar pelo nome, CRM ou CRMUF"
          />
          <div class="sm:w-2/5 w-full min-h-[52px]">
            <simple-select
              class="h-[52px]"
              :opts="[
                {
                  label: 'CRM/UF',
                  value: 0,
                  placeholder: true
                },
                {
                  label: 'MG',
                  value: 1
                },
                {
                  label: 'SP',
                  value: 2
                },
                {
                  label: 'BA',
                  value: 3
                }
              ]"
              :required="true"
            />
          </div>
        </div>
      </div>
      <div v-show="tab === 2">
        <div class="flex justify-between">
          <div class="md:max-w-[500px] w-full">
            <Input v-model="searchInputCRM" width="100%" label="Buscar pelo CRM" />
          </div>
          <div class="w-full md:max-w-[120px] hidden md:inline">
            <FilledButton
              @click="openCreateInviteModal"
              text="Criar convite"
              width="100%"
            />
          </div>
        </div>
      </div> -->
    </div>

    <!-- Tabs -->
    <ul
      class="sm:gap-14 gap-0 border-b flex sm:justify-start justify-between text-white select-none"
    >
      <li>
        <button
          class="w-max"
          @click="() => (tab = 1)"
          :class="tab === 1 ? activeClass : inactiveClass"
        >
          Meus Médicos
        </button>
      </li>

      <li>
        <button
          class="w-max"
          @click="() => (tab = 2)"
          :class="tab === 2 ? activeClass : inactiveClass"
        >
          Médicos pendentes
        </button>
      </li>
    </ul>

    <!-- Content -->
    <div class="flex flex-col gap-10 sm:h-[570px] justify-between">
      <div v-if="tab === 1">
        <CardsTab :companyDoctors="companyDoctors" />
        <v-pagination
          v-model="currentPageTab1"
          :total-visible="itemsPerPageTab1"
          :length="pageCountTab1"
          density="comfortable"
          color="primary"
          @update:model-value="changePageTab1"
        />
      </div>
      <div v-if="tab === 2">
        <OutstandingDoctors :companyDoctors="companyDoctors" />
        <div class="w-full md:max-w-[120px] inline md:hidden">
          <FilledButton
            @click="openCreateInviteModal"
            text="Criar convite"
            width="100%"
          />

        </div>
        <v-pagination
            v-model="currentPageTab2"
            :total-visible="itemsPerPageTab2"
            :length="pageCountTab2"
            density="comfortable"
            color="primary"
            @update:model-value="changePageTab2"
        />
      </div>
    </div>
  </div>

  <FilterModal
    :modalStateProp="filterModalProps"
    @close="closeFilterModal"
    @apply-filters="applyFilters"
  />

  <CreateInviteModal
  :modalStateProp="createInviteModalProps"
  :updateListFunction="getCompanyDoctors"
  @close="closeCreateInviteModal"
  />
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useStore } from 'vuex'
// import Paginate from '@/components/designSystem/Paginate/index.vue'
import { CreateInviteModal, FilterModal } from './components/atoms'
import { CardsTab, OutstandingDoctors } from './components/organisms/index'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import Network from '@/Network'
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
import { CompanyDoctorInviteStatusEnum, CompanyDoctorInviteSearchParams } from '@/Network/Types/Requests/CompanyRequests'
import { mdiFilter } from '@mdi/js'

const store = useStore()
const activeClass = ref('inline-block py-4  border-b-2 border-primary text-black font-semibold')
const inactiveClass = ref('inline-block py-4 text-gray-500')
const tab = ref(1)

const currentPageTab1 = ref(1)
const itemsPerPageTab1 = ref(15) 
const totalItemsTab1 = ref(0) 


const currentPageTab2 = ref(1)
const itemsPerPageTab2 = ref(2) 
const totalItemsTab2 = ref(0) 

const searchInput = ref('')
const searchInputCRM = ref('')

const createInviteModalProps = ref(false)
const filterModalProps = ref(false)

const companyDoctors = ref<DoctorCompanyResponse[]>()

interface FiltersProcessed {
  names: string[]
  crms: string[]
  crmUFs: string[]
  companies: string[] | number[]
}

const processedFiltersTab1 = ref<FiltersProcessed>({
  names: [],
  crms: [],
  crmUFs: [],
  companies: []
})

const processedFiltersTab2 = ref<FiltersProcessed>({
  names: [],
  crms: [],
  crmUFs: [],
  companies: []
})

const pageCountTab1 = computed(() => {
  return Math.ceil(totalItemsTab1.value / itemsPerPageTab1.value)
})

const pageCountTab2 = computed(() => {
  return Math.ceil(totalItemsTab2.value / itemsPerPageTab2.value)
})

const calculateOffsetTab1 = computed(() => {
  return (currentPageTab1.value - 1) * itemsPerPageTab1.value
})

const calculateOffsetTab2 = computed(() => {
  return (currentPageTab2.value - 1) * itemsPerPageTab2.value
})

const changePageTab1 = (page: number) => {
  currentPageTab1.value = page
  getCompanyDoctors(transformFilters(processedFiltersTab1.value))
}

const changePageTab2 = (page: number) => {
  currentPageTab2.value = page
  getCompanyPendingDoctors(transformFilters(processedFiltersTab2.value))
}

watch(searchInput, () => {
  store.dispatch('filterCards', searchInput.value)
})

watch(searchInputCRM, () => {
  store.dispatch('filterCrm', searchInputCRM.value)
})

const getCompanyDoctors = async (filters?: Partial<CompanyDoctorInviteSearchParams>) => {
  try {
    const { data, count } = await Network.company.getCompanyDoctors({
      search: searchInput.value,
      inviteStatus: CompanyDoctorInviteStatusEnum.ACCEPTED,
      ...filters,
      limit: itemsPerPageTab1.value,
      offset: calculateOffsetTab1.value
    })
    companyDoctors.value = data
    totalItemsTab1.value = count
  } catch (error) {
    console.error('Erro ao obter médicos da empresa:', error)
  }
}

const getCompanyPendingDoctors = async (filters?: Partial<CompanyDoctorInviteSearchParams>) => {
  try {

    const { data, count } = await Network.company.getCompanyDoctors({
      search: searchInput.value,
      inviteStatus: CompanyDoctorInviteStatusEnum.PENDING,
      ...filters,
      limit: itemsPerPageTab2.value,
      offset: calculateOffsetTab2.value
    })
    companyDoctors.value = data
    totalItemsTab2.value = count
  } catch (error) {
    console.error('Erro ao obter médicos pendentes:', error)
  }
}

const openCreateInviteModal = () => {
  createInviteModalProps.value = true
}

const closeCreateInviteModal = () => {
  createInviteModalProps.value = false
}

const openFilterModal = () => {
  filterModalProps.value = true
  searchInput.value = ''
}

const closeFilterModal = () => {
  filterModalProps.value = false
}

interface Company {
  title: string
  value: string
}

interface Filters {
  names: string[]
  crms: string[]
  crmUFs: string[]
  companies: Company[]
}

const applyFilters = (filters: Filters) => {
  const companyIds = filters.companies.map(company => company.value)
  console.log(filters)
  if (tab.value === 1) {
    processedFiltersTab1.value = {
      names: filters.names,
      crms: filters.crms,
      crmUFs: filters.crmUFs,
      companies: companyIds
    }
    
  } else if (tab.value === 2) {
    processedFiltersTab2.value = {
      names: filters.names,
      crms: filters.crms,
      crmUFs: filters.crmUFs,
      companies: companyIds
    }
  }

  currentPageTab1.value = 1
  currentPageTab2.value = 1
}

watch(processedFiltersTab1, (newFilters) => {
  getCompanyDoctors(transformFilters(newFilters))
}, { deep: true })

watch(processedFiltersTab2, (newFilters) => {
  getCompanyPendingDoctors(transformFilters(newFilters))
}, { deep: true })

function transformFilters(source: FiltersProcessed): Partial<CompanyDoctorInviteSearchParams> {
  const queryFilters: Partial<CompanyDoctorInviteSearchParams> = {}

  if (source.names.length > 0) {
    queryFilters.names = source.names.join(',')
  }

  if (source.crms.length > 0) {
    queryFilters.crms = source.crms.join(',')
  }

  if (source.crmUFs.length > 0) {
    queryFilters.crmUFs = source.crmUFs.join(',')
  }

  if (source.companies.length > 0) {
    queryFilters.companies = source.companies.join(',')
  }

  return queryFilters
}

const clearFilters = () => {

  const clearFilters: Filters = {
    names:[],
    crms:[],
    crmUFs:[],
    companies: []
  }

  applyFilters(clearFilters)
  
  searchInput.value = ''

  currentPageTab1.value = 1
  currentPageTab2.value = 1

}

const isFilterApplied = computed(() => {
  return (
    processedFiltersTab1.value.names.length > 0 ||
    processedFiltersTab1.value.crms.length > 0 ||
    processedFiltersTab1.value.crmUFs.length > 0 ||
    processedFiltersTab1.value.companies.length > 0 ||

    processedFiltersTab2.value.names.length > 0 ||
    processedFiltersTab2.value.crms.length > 0 ||
    processedFiltersTab2.value.crmUFs.length > 0 ||
    processedFiltersTab2.value.companies.length > 0 
  )
})

</script>

<style scoped>
.title {
  font-size: 1.25rem;
}

.tabs {
  border-bottom-width: 2px;
}

.tabs > li > button {
  min-width: 80px;
  text-align: start;
}
</style>
