<template>
    <modal-vue @close="close" :modalActive="modalStateProp">
      <template #header>
        <p class="font-bold text-start">Filtrar unidades</p>
      </template>
  
      <div class="flex flex-col gap-4">
        <!-- Campo de Filtro por Nomes -->
        <v-combobox
          ref="nameCombobox"
          v-model="selectedNames"
          label="Insira os Nomes"
          multiple
          tags
          chips
          clearable
          deletable-chips
          variant="outlined"
          class="w-full"
          @keydown.enter.prevent="handleEnterName" 
          :search-input.sync="searchName"
          v-model:menu="nameMenu" 
        />
  
        <!-- Campo de Filtro por CNPJs -->
        <v-combobox
          ref="cnpjCombobox" 
          v-model="selectedCnpjs"
          label="Insira os CNPJs"
          multiple
          tags
          chips
          clearable
          deletable-chips
          variant="outlined"
          class="w-full"
          @keydown.enter.prevent="handleEnterCnpj"
          :search-input.sync="searchCnpj"
          v-model:menu="cnpjMenu"
        />
  
        <!-- Campo de Filtro por UFs -->
        <v-autocomplete
          ref="ufAutocomplete"
          v-model="selectedUfs"
          :items="StatesFromBR"
          label="Selecione as UFs"
          multiple
          chips
          clearable
          deletable-chips
          variant="outlined"
          class="w-full"
          @keydown.enter.prevent="handleEnterUf"
          :search-input.sync="searchUf"
          v-model:menu="ufMenu"
        />
  
        <!-- Campo de Filtro por Empresas -->
        <v-combobox
          ref="companyCombobox" 
          v-model="selectedCompanies"
          :items="listCompanies"
          label="Selecione as Empresas"
          multiple
          tags
          chips
          clearable
          deletable-chips
          variant="outlined"
          class="w-full"
          @keydown.enter.prevent="handleEnterCompany" 
          :search-input.sync="searchCompany"
          v-model:menu="companyMenu" 
        />
      </div>
  
      <template #footer>
        <div class="flex flex-col md:flex-row md:justify-end gap-2">
          <div class="w-full md:max-w-[140px] order-2 md:order-1">
            <OutlineButton @click="close" text="Cancelar" width="100%" />
          </div>
          <div class="w-full md:max-w-[140px] order-1 md:order-2">
            <FilledButton
              :disabled="!isFilterValid"
              :loading="loading"
              @click="applyFilters"
              text="Aplicar Filtros"
              width="100%"
            />
          </div>
        </div>
      </template>
    </modal-vue>
  </template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, onMounted, nextTick, computed } from 'vue'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import { useToast } from 'vue-toastification'
import StatesFromBR from '@/config/StatesFromBR'
import { storeToRefs } from 'pinia'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'

const props = defineProps<{ modalStateProp: boolean }>()
const emit = defineEmits(['close', 'apply-filters'])

const loading = ref(false)
const { listCompanies } = storeToRefs(useCompanyStore())
const toast = useToast()

const selectedNames = ref<string[]>([])
const selectedCnpjs = ref<string[]>([])
const selectedUfs = ref<string[]>([])
const selectedCompanies = ref<string[]>([])

const searchName = ref('')
const nameMenu = ref(false)

const searchCnpj = ref('')
const cnpjMenu = ref(false)

const searchUf = ref('')
const ufMenu = ref(false)

const searchCompany = ref('')
const companyMenu = ref(false)

const nameCombobox = ref(null) 
const cnpjCombobox = ref(null)
const ufAutocomplete = ref(null)
const companyCombobox = ref(null) 

const isFilterValid = computed(() => {
  return (
    selectedNames.value.length > 0 ||
    selectedCnpjs.value.length > 0 ||
    selectedUfs.value.length > 0 ||
    selectedCompanies.value.length > 0
  )
})

const applyFilters = async () => {
  if (
    selectedNames.value.length === 0 &&
    selectedCnpjs.value.length === 0 &&
    selectedUfs.value.length === 0 &&
    selectedCompanies.value.length === 0
  ) {
    toast.error('Por favor, selecione pelo menos um filtro para aplicar.')
    return
  }

  try {
    loading.value = true
    emit('apply-filters', {
      names: selectedNames.value,
      cnpjs: selectedCnpjs.value,
      ufs: selectedUfs.value,
      companies: selectedCompanies.value
    })
    close()
  } catch (error) {
    console.error(error)
    toast.error('Erro ao aplicar filtros. Tente novamente.')
  } finally {
    loading.value = false
  }
}

const handleEnterName = async () => {
  const newName = searchName.value.trim()

  if (newName && !selectedNames.value.includes(newName)) {
    selectedNames.value.push(newName)
    toast.success(`Nome "${newName}" adicionado.`)
    searchName.value = ''
  } else if (selectedNames.value.includes(newName)) {
    toast.info(`Nome "${newName}" já está na lista.`)
    searchName.value = ''
  }

  nameMenu.value = false
}

const handleEnterCnpj = async () => {
  const newCnpj = searchCnpj.value.trim()

  if (newCnpj && !selectedCnpjs.value.includes(newCnpj)) {
    selectedCnpjs.value.push(newCnpj)
    toast.success(`CNPJ "${newCnpj}" adicionado.`)
    searchCnpj.value = '' 
  } else if (selectedCnpjs.value.includes(newCnpj)) {
    toast.info(`CNPJ "${newCnpj}" já está na lista.`)
    searchCnpj.value = ''
  }

  cnpjMenu.value = false
}

const handleEnterUf = async () => {
  const newUf = searchUf.value.trim().toUpperCase()

  if (newUf && !selectedUfs.value.includes(newUf)) {
    selectedUfs.value.push(newUf)
    toast.success(`UF "${newUf}" adicionada.`)
    searchUf.value = ''
  } else if (selectedUfs.value.includes(newUf)) {
    toast.info(`UF "${newUf}" já está na lista.`)
    searchUf.value = ''
  }

  ufMenu.value = false

  await nextTick()
  if (ufAutocomplete.value && typeof ufAutocomplete.value.blur === 'function') {
    ufAutocomplete.value.blur()
  }
}

const handleEnterCompany = async () => {
  const newCompany = searchCompany.value.trim()
  if (newCompany && !selectedCompanies.value.includes(newCompany)) {
    selectedCompanies.value.push(newCompany)
    toast.success(`Empresa "${newCompany}" adicionada.`)
    searchCompany.value = ''
  } else if (selectedCompanies.value.includes(newCompany)) {
    toast.info(`Empresa "${newCompany}" já está na lista.`)
    searchCompany.value = ''
  }

  companyMenu.value = false

  await nextTick()
  if (companyCombobox.value && typeof companyCombobox.value.blur === 'function') {
    companyCombobox.value.blur()
  }
}

const close = () => {
  selectedNames.value = []
  selectedCnpjs.value = []
  selectedUfs.value = []
  selectedCompanies.value = []
  emit('close')
}
</script>
