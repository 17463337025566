<template>
  <div class="w-full h-full grid-layout my-0">
    <div class="w-full flex flex-col lg:flex-row justify-between items-start gap-4">
      
      <div class="w-full lg:w-3/5">
        <SearchInput
          v-model="textInput"
          width="100%"
          label="Buscar unidade"
          :searchFunction="search"
        />
      </div>

      <div class="w-full lg:w-1/5 flex flex-col sm:flex-row gap-1">        
          <FilledButton
            @click="isFilterApplied ? clearFilters() : openFilterModal()"
            :text="isFilterApplied ? 'Limpar Filtros' : 'Filtrar'"
            height="56px"
            :prepend-icon="!isFilterApplied ? mdiFilter : ''"
            class="w-full sm:w-auto bg-gray-500 hover:bg-gray-600"
          />
      </div>
      <div class="w-full lg:w-1/5 flex flex-col sm:flex-row gap-1">        
          <FilledButton 
            @click="handleOpenModal('create')" 
            text="Cadastrar unidade" 
            height="56px"
            class="w-full sm:w-auto"
          />
      </div>
    </div>

    <div class="w-full mt-4">
      <div class="grid xl:flex grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:flex-wrap my-0 gap-4">
        <template v-for="(unit, index) in healthUnits?.data || []" :key="index">
          <CardHospital @mousedown="setHealthUnitToUpdate(unit)" :unit="unit" />
        </template>
      </div>
      <div class="mt-4">
        <v-pagination
          v-if="totalItems > itemsPerPage"
          v-model="currentPage"
          :total-visible="itemsPerPage"
          :length="pageCount"
          density="comfortable"
          color="primary"
          @update:model-value="changePage"
        />
      </div>
    </div>

    <!-- <div
      class="grid grid-cols-1 lg:hidden gap-4 my-0 mt-4"
      style="grid-area: buttons; height: 52px"
    >
      <FilledButton @click="handleOpenModal('create')" text="Cadastrar unidade" width="100%" />
    </div> -->
  </div>

  <HealthUnitModal
    v-if="toggleHealthUnitModal"
    :healthUnit="healthUnitToUpdate"
    :modalStateProp="toggleHealthUnitModal"
    @close="closeHealthUnitModal"
    :type="operationModal"
    :limit="itemsPerPage"
    :offset="calculateOffset"
  />

  <FilterModal
    :modalStateProp="filterModalProps"
    @close="closeFilterModal"
    @apply-filters="applyFilters"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import CardHospital from './components/CardHospital.vue'
import HealthUnitModal from './modals/health.unit.modal.vue'
import FilterModal from './modals/FilterModal.vue'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import { HealthUnitResponse } from '@/Network/Types/Responses/HealthUnitResponses'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { FiltersHealthUnits } from '@/Network/Types/Requests/HealthUnitRequests'
import { mdiFilter } from '@mdi/js'

const { healthUnits } = storeToRefs(useHealthUnitStore())
const healthUnitStore = useHealthUnitStore()

const textInput = ref('')

const currentPage = ref(1)
const itemsPerPage = ref(15)
const totalItems = ref(0)
const forceSync = ref<boolean>(true)

const filters = ref<FiltersHealthUnits>({
  names: '',
  cnpjs: '',
  ufs: '',
  companies: '',
  limit: itemsPerPage.value,
  offset: 0
})

interface Company {
  title: string
  value: string
}

interface FiltersArray {
  names: string[]
  cnpjs: string[]
  ufs: string[]
  companies: Company[]
}

const pageCount = computed(() => {
  return Math.ceil(totalItems.value / itemsPerPage.value)
})

const calculateOffset = computed(() => {
  return (currentPage.value - 1) * itemsPerPage.value
})

const isFilterApplied = computed(() => {
  return (
    filters.value.names !== '' ||
    filters.value.cnpjs !== '' ||
    filters.value.ufs !== '' ||
    filters.value.companies !== ''
  )
})

const filterModalProps = ref(false)
const closeFilterModal = () => {
  filterModalProps.value = false
}
const openFilterModal = () => {
  textInput.value = ''
  filterModalProps.value = true
}

const closeHealthUnitModal = () => {  
  toggleHealthUnitModal.value = false
}

const operationModal = ref<'create' | 'update'>('create')
const toggleHealthUnitModal = ref(false)
const healthUnitToUpdate = ref<HealthUnitResponse>()

const emit = defineEmits(['close', 'apply-filters'])

const handleOpenModal = (operation: 'create' | 'update') => {
  operationModal.value = operation
  toggleHealthUnitModal.value = true
}

const setHealthUnitToUpdate = (unit: HealthUnitResponse) => {
  healthUnitToUpdate.value = unit
  handleOpenModal('update')
}

const search = async (queryFilters?: Partial<FiltersHealthUnits>) => {
  if (queryFilters) {
    filters.value = { ...queryFilters, limit: itemsPerPage.value, offset: calculateOffset.value }
  } else {
    filters.value = {
      names: textInput.value,
      cnpjs: '',
      ufs: '',
      companies: '',
      limit: itemsPerPage.value,
      offset: 0
    }
  }

  await healthUnitStore.getAllHealthUnits(forceSync.value, filters.value)
  totalItems.value = healthUnits.value?.count || 0
}

onMounted(async () => {
  await search()
})

const applyFilters = (modalFields: FiltersArray) => {
  const companyIds = modalFields.companies.map(company => company.value)

  const queryFilters: Partial<FiltersHealthUnits> = {
    names: modalFields.names.join(','),
    cnpjs: modalFields.cnpjs.join(','),
    ufs: modalFields.ufs.join(','),
    companies: companyIds.join(','),
    limit: itemsPerPage.value,
    offset: 0
  }

  currentPage.value = 1
  search(queryFilters)
}

const changePage = async (page: number) => {
  currentPage.value = page
  const queryFilters: Partial<FiltersHealthUnits> = {
    ...filters.value,
    offset: calculateOffset.value
  }
  search(queryFilters)
}

const clearFilters = () => {
  filters.value = {
    names: '',
    cnpjs: '',
    ufs: '',
    companies: '',
    limit: itemsPerPage.value,
    offset: 0
  }
  textInput.value = ''
  currentPage.value = 1
  search()
}

</script>